
import request from '@/utils/request'

export function createFilter (data) {
  return request({
    url: '/api-manager/filter',
    method: 'post',
    data
  })
}

export function updateFilter (_id, data) {
  return request({
    url: '/api-manager/filter/' + _id,
    method: 'put',
    data
  })
}

export function getFilter (_id) {
  return request({
    url: '/api-manager/filter/' + _id,
    method: 'get'
  })
}

export function search (params) {
  return request({
    url: '/api-manager/filter/search',
    method: 'post',
    params
  })
}

export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/filter/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function deleteFilter (id) {
  return request({
    url: `/api-manager/filter/${id}`,
    method: 'delete'
  })
}
