<template>
  <div class="app-container" v-loading="loading">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Editar efecto</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-row justify="space-around" align="middle">
                <el-col :sm="12" :md="12" :lg="12" align="middle">
                  <el-form-item label="Miniatura de efecto">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.miniature"
                      @getImage="getSelectedMiniature"
                      ></ImageUpload>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="12" align="middle">
                  <el-form-item label="Imagen de efecto">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.image"
                      @getImage="getSelectedImage"
                      ></ImageUpload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <el-form-item label="Mostrar en aplicación">
                    <el-switch
                      v-model="form.showInApp"
                      active-text="Si"
                      inactive-text="No">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="left">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Título">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerUpdate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Actualizar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { updateFilter, getFilter } from '@/api/filter.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi

export default {
  name: 'editFilter',
  components: {
    ImageUpload
  },
  data () {
    return {
      host: urlServer,
      form: {
        _id: '',
        title: '',
        image: null,
        showInApp: true,
        miniature: null
      },
      loadingImage: false,
      loading: false
    }
  },
  mounted () {
    var idTemp = this.$route.params._id
    if (idTemp) {
      this.form._id = idTemp
    }
    this.fetchData()
  },
  created () {
  },
  methods: {
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedMiniature (imageUploaded) {
      this.form.miniature = imageUploaded
    },
    getSelectedImage (imageUploaded) {
      this.form.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.image)
    },
    async fetchData () {
      try {
        this.loading = true
        await getFilter(this.form._id).then((response) => {
          if (response.success) {
            this.form = response.data
          } else {
            console.log(response)
          }
        }).catch(this.responseCatch)
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      } finally {
        this.loading = false
      }
    },
    handlerCancel () {
      this.$router.push('/filters')
    },
    async handlerUpdate (info) {
      console.log(this.form)
      var invalidateMiniature = this.form.miniature !== '' && this.form.miniature !== null
      var invalidateImage = this.form.image !== '' && this.form.image !== null

      if (invalidateImage || invalidateMiniature) {
        if (_.isEqual(this.form, this.backupForm)) {
          this.$router.push('/filters')
        } else {
          try {
            const response = await updateFilter(this.form._id, this.form)
            if (response.success) {
              console.log(response)
              this.$message({
                showClose: true,
                message: '¡Se actualizó correctamente!',
                type: 'success'
              })
              this.$router.push('/filters')
            } else {
              console.log(response)
            }
          } catch (err) {
            if (err.response && err.response.data && err.response.data.messages) {
              err.response.data.messages.forEach(message => {
                this.$message.error(`${message}`)
              })
            } else {
              console.log(err)
            }
          }
        }
      } else {
        this.$message.error('Asegurate que no falte ningun archivo o dato')
      }
    }
  }
}
</script>

<style>
.label-form-validate-wrong {
  color: #F56C6C;
}

.size-form-item > .el-form-item {
  margin: 0;
}
.size-form-item > .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
.my-autocomplete {
  width: 500px;
}
</style>
